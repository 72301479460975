// 'use server';

import React from 'react';
import { UseFormReturn, Controller } from "react-hook-form"
import { Input } from '@/components/ui/input';
import { validator } from '../../utils';
import { ErrorFeedback } from '../ui/error-feedback';
import { cn } from '@/lib/utils';

type FormType = {
  amount: string
}

type FreebieInputType = {
  paymentType: string;
  onAmountChange: (amount: number) => void;
  specificAmount: number;
  paymeLink: string;
  formState: UseFormReturn<FormType>
}

export const FreebieInput: React.FC<FreebieInputType> = ({
  paymentType, formState, onAmountChange, specificAmount, paymeLink
}) => {
  const { register, control, formState: formUtil } = formState;
  const { errors } = formUtil;

  if (paymentType === 'voluntary') {
    return (
      <div className="w-full pt-[20px] px-[10px]">
        <div className={cn(
          "checkout-input flex min-h-[36px] items-center border-[1px] rounded-md overflow-hidden",
          Boolean(errors.amount) ? "border-[#fac6cc]" : "border-[#e6ecf0]"
        )}>
          {/* Prefix */}
          <div className="flex items-center min-h-[36px] h-[40px] px-[12px] rounded-l-sm bg-gray-100 text-gray-500 text-[1rem]">
            Rp
          </div>
          {/* Input */}
          <Controller
            name="amount"
            control={control}
            rules={{
              min: validator.min(specificAmount),
              max: validator.max(1_000_000_000),
            }}
            render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
              <Input
                ref={ref}
                id="amount"
                type="number"
                autoComplete="on"
                aria-describedby="amount-hint"
                onChange={(e) => {
                  onChange(e);
                  onAmountChange(Number(e.currentTarget.value)); // Custom handler
                }}
                value={value}
                onBlur={onBlur}
                invalid={Boolean(error)}
                placeholder={paymeLink === "pemudahijrah" ? 'Infaq Terbaik' :`${specificAmount}+`}
                className={`w-full min-h-[36px] px-[17px] py-[7.5px] text-gray-700 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent rounded-l-none border-none text-[1rem]`}
              />
            )}
          />
        </div>
        <ErrorFeedback id="amount-hint">{errors.amount?.message}</ErrorFeedback>
      </div>
    );
  }

  return null;
};
