import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { validator } from '../../utils';
import { ErrorFeedback } from '../ui/error-feedback';
import { Input } from '../ui/input';
import { useQuery } from '@apollo/client';
import MayarAlert from '../alert/alert';
import { GET_STOCK_SAAS } from '../../gqls';
import { Icon } from '@iconify/react';

const ProductQuantityInput = ({
  name,
  paymentLink = {},
  formState,
  qty,
  changeQty,
  changeLimit,
  setOutOfStock,
  isProductTypeUnlimited, // If this is true, then it means the product type have unlimited stock of license code. So don't bother processing the stock.
}) => {
  const { t } = useTranslation();
  const { register, formState: formUtil } = formState;
  const { errors } = formUtil;
  const [limitPurchase, setLimitPurchase] = useState(0);
  const [purchasedQty, setPurchasedQty] = useState(qty);
  const [isStockEmpty, setIsStockEmpty] = useState(false);

  const { data, loading, error } = useQuery(GET_STOCK_SAAS, {
    variables: {
      link: paymentLink.link,
    },
    context: { public: true },
    fetchPolicy: 'network-only',
    skip: !paymentLink.link || paymentLink.type !== 'saas',
  });

  const stockProduct = _.get(data, 'getStockLicenseCode', {});

  useEffect(() => {
    if (purchasedQty > limitPurchase || !purchasedQty) {
      setOutOfStock(true);
    } else {
      setOutOfStock(false);
    }
  }, [purchasedQty, limitPurchase]);

  useEffect(() => {
    if (stockProduct.isStockUnlimited || isProductTypeUnlimited) {
      setLimitPurchase(50);
      changeLimit(50);
      setIsStockEmpty(false);
    } else if (stockProduct.total && stockProduct.total >= 50) {
      setLimitPurchase(50);
      changeLimit(50);
      setIsStockEmpty(false);
    } else if (stockProduct.total && stockProduct.total < 50) {
      setLimitPurchase(stockProduct.total);
      changeLimit(stockProduct.total);
      setIsStockEmpty(false);
    } else {
      setIsStockEmpty(true);
      setOutOfStock(true);
    }
  }, [stockProduct, isProductTypeUnlimited]);

  if (loading) {
    return <Icon icon="svg-spinners:180-ring-with-bg" className='text-3xl' />
  }

  if (error) {
    return <MayarAlert isError message={t('saas.errLicenseStock')} />;
  }

  if (isStockEmpty) {
    return (
      <div className='w-full pt-[20px] px-[10px] mb-0'>
        <MayarAlert isError customClass="w-full" message={t('cart.outOfStock')} />
      </div>
    );
  }

  return (
    <div className='w-full pt-[20px] px-[10px]'>
      <Input
        id={name}
        name={name}
        type="number"
        aria-describedby="quantity-hint"
        bsSize="lg"
        placeholder={t('word.quantity')}
        className="checkout-input bg-[#fbfcfc] min-h-[36px] px-[17px] pt-[7.5px] pb-[9px] text-[1rem] border-[1px] border-[#e6ecf0]"
        min={1}
        max={limitPurchase}
        defaultValue={purchasedQty || 1}
        invalid={Boolean(errors[name])}
        disabled={!stockProduct.total && !stockProduct.isStockUnlimited && !isProductTypeUnlimited}
        {...register(name, {
          required: validator.required,
          min: validator.min(1),
          max: validator.max(limitPurchase),
        })}
        onChange={(e) => {
          setPurchasedQty(parseInt(e.target.value));
          changeQty(parseInt(e.target.value));
        }}
      />

      <ErrorFeedback id="quantity-hint">{errors[name]?.message}</ErrorFeedback>

      <small className="mt-[1px] text-[12px]">
        <div className="text-red-500">
          {purchasedQty > limitPurchase && `${t('saas.reachedLimit')} ${limitPurchase}`}
        </div>

        {(stockProduct.isStockUnlimited || isProductTypeUnlimited) && t('checkout.unlimitedStock')}

        {!stockProduct.isStockUnlimited &&
          !stockProduct.total &&
          !isProductTypeUnlimited &&
          t('word.emptyStock')}

        {!stockProduct.isStockUnlimited &&
          stockProduct.total &&
          !isProductTypeUnlimited &&
          `${t('checkout.stockRem')} : ${stockProduct.total}`}
      </small>
    </div>
  );
};

export default ProductQuantityInput;

ProductQuantityInput.defaultProps = {
  name: 'quantity',
  paymentLink: {},
  formState: {},
  placeholder: '',
  qty: 1,
  isProductTypeUnlimited: false,
};
