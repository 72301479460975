import './style.scss';
import React from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';
import { debounce } from 'lodash';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useApolloClient } from '@apollo/client';
import { ErrorFeedback } from '../ui/error-feedback';
import { GQL_GET_LOCATION_BY_KECAMATAN } from '../../gqls';
import { validator } from '../../utils';
import { CustomStyles, CustomStylesInvalid } from '../custom-styles/react-select-styles';

const SelectAddress = ({ formState, onValueChange, defaultValue }) => {
  const { t } = useTranslation();
  const client = useApolloClient();
  const { formState: formUtil, control } = formState;
  const { errors } = formUtil;

  console.log({errors})
  const mapOptionsToValues = (options) => {
    if (options) {
      return options.map((option) => ({
        value: option,
        label: option.city,
      }));
    }
    return [];
  };

  const _loadSuggestions = (keyword, callback) => {
    if (!keyword || keyword.length < 3) {
      return callback([]);
    }
    client
      .query({
        query: GQL_GET_LOCATION_BY_KECAMATAN,
        variables: {
          keyword,
        },
        context: {
          queryDeduplication: false,
          public: true,
        },
        fetchPolicy: 'network-only',
      })
      .then((resp) => callback(mapOptionsToValues(resp?.data?.getDistictKiriminAjabyName)));
  };

  const loadSuggestions = debounce(_loadSuggestions, 500);

  return (
    <div className="mb-0">
      <Controller
        name="address"
        control={control}
        rules={{ required: validator.required }}
        defaultValue={defaultValue}
        // render={({ onChange, name, value }) => (
        render={({ field, fieldState, formState }) => (
          <AsyncSelect
            id={field.name}
            name={field.name}
            className={`form-control-lg p-0 checkout-input ${
              Boolean(formState?.errors?.address) ? 'is-invalid' : ''
            }`}
            styles={Boolean(formState?.errors?.address) ? CustomStylesInvalid : CustomStyles}
            loadOptions={loadSuggestions}
            escapeClearsValue
            value={field.value}
            openMenuOnClick={false}
            isSearchable
            isClearable
            placeholder={t('placeholder.searchAddress')}
            cacheOptions={false}
            onChange={(e) => {
              field.onChange(e);
              onValueChange(e);
            }}
          />
        )}
      />
      <ErrorFeedback id="address-hint">{errors?.address?.message && errors?.address?.message}</ErrorFeedback>
    </div>
  );
};

SelectAddress.defaultProps = {
  formState: {},
  defaultValue: null,
};

SelectAddress.propTypes = {
  formState: PropTypes.object,
  defaultValue: PropTypes.object,
  onValueChange: PropTypes.func,
};

export default SelectAddress;
