/**
 * External Dependencies
 */

import React from 'react';
import * as FeatherIcons from 'react-feather';
import * as far from '@fortawesome/free-regular-svg-icons';
import * as fas from '@fortawesome/free-solid-svg-icons';
import * as fab from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import changeCase from 'change-case';

const faLib = { far, fas, fab };

/**
 * Component
*
* @param {Object} props component props.
* @return {Object} component.
*/
const Icon = (props) => {
  const { vendor = 'feather', strokeWidth = '1.5', defaultClass = true } = props;
  let { name } = props;
  
  const options = {
    ...props,
    className: `${props.className || ''} ${defaultClass ? 'rui-icon' : ''}`,
    };
    
    delete options.defaultClass;
    delete options.vendor;
    
    // Feather.
    if ('feather' === vendor) {
    name = changeCase?.pascalCase(name);
    const RenderIcon = FeatherIcons[name] || 'AlertTriangle';

    return <RenderIcon {...options} strokeWidth={strokeWidth} />;
  }

  // FontAwesome.
  if ('fa' === vendor) {
    let icon = '';
    let lib = 'far';

    if (Array.isArray(name)) {
      lib = name[0];
      name = name[1];
    }

    name = `fa${changeCase.pascalCase(name)}`;

    if (faLib[lib] && faLib[lib][name]) icon = faLib[lib][name];
    if (icon) return <FontAwesomeIcon {...options} icon={icon} />;
  }

  return '';
};

export default Icon;
