import * as React from "react";
import { cn } from "@/lib/utils";
import { Icon } from "@iconify/react";
import { Button } from "./button";
export interface ErrorFeedbackProps {
  children?: React.ReactNode;
  className?: string;
  variant?:
    | "primary"
    | "secondary"
    | "success"
    | "danger"
    | "warning"
    | "info"
    | "light"
    | "dark";
  onCloseAlert?: () => void;
  isCloseable?: boolean;
  noVariant?: boolean;
  // [key: string]: any; // Allow any additional props
}

const MayarAlertBase: React.FC<ErrorFeedbackProps> = ({
  children,
  className,
  variant,
  onCloseAlert,
  isCloseable = true,
  noVariant,
  ...props
}) => {
  if (!children) {
    return null;
  }

  const classVariant =
    variant === "primary"
      ? "bg-[#cfe2ff] text-[#084298] border-[#b6d4fe] border-[1px]"
      : variant === "secondary"
        ? "text-[#41464b] bg-[#e2e3e5] border-[#d3d6d8] border-[1px]"
        : variant === "success"
          ? "text-[#0f5132] bg-[#d1e7dd] border-[#badbcc] border-[1px]"
          : variant === "danger"
            ? "text-[#6c757d] bg-[#f8f9fa] border-[#ef5164] border-l-[2px]"
            : variant === "warning"
              ? "text-[#664d03] bg-[#fff3cd] border-[#ffecb5] border-[1px]"
              : variant === "info"
                ? "text-[#055160] bg-[#cff4fc] border-[#b6effb] border-[1px]"
                : variant === "light"
                  ? "text-[#6c757d] bg-[#f8f9fa] border-[#f8f9fa] border-[1px]"
                  : variant === "dark"
                    ? "text-[#141619] bg-[#d3d3d4] border-[#bcbebf] border-[1px]"
                    : "text-[#0f5132] bg-[#d1e7dd] border-[#badbcc] border-[1px]";

  return (
    <div
      className={cn(
        `py-[19px] px-[25px] relative text-[13px]`,
        !noVariant && classVariant,
        className
      )}
      {...props}
    >
      {isCloseable && (
        <Button
          variant="ghost"
          type="button"
          className="absolute right-0 top-0 py-2 px-3 flex justify-center items-center"
          onClick={onCloseAlert}
        >
          <Icon icon="mingcute:close-fill" />
        </Button>
      )}
      {children}
    </div>
  );
};

export { MayarAlertBase };
