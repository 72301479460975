"use client";

import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorFeedback } from '../ui/error-feedback';
import { MayarAlertBase } from '../ui/mayar-alert-base';
import MembershipTier from '../membership-tier';

const SelectTiers = ({ paymentLink, onTierSelected, invalid, selectedTier }) => {
  const { t } = useTranslation();
  const membershipTiers = paymentLink?.membershipTier || [];

  if (paymentLink?.type === 'membership') {
    return (
      <div className='pt-[20px] px-[10px] w-full mt-2'>
        <h5 className="mb-[15px] w-full text-center font-[500]">
          {t('form.chooseTier')}
        </h5>

        {membershipTiers?.length < 1 && (
          <MayarAlertBase variant="light" className="text-black text-center">
            {t('alert.unactiveTier')}
          </MayarAlertBase>
        )}

        <div className="my-0 w-full">
          {membershipTiers?.map((item, index) => {
            const lastIndex = membershipTiers?.length - 1;

            if (item?.status !== 'ACTIVE') {
              return;
            }

            return (
              <React.Fragment key={index}>
                <div
                  className={`p-0 border-0 w-full ${index === lastIndex ? '' : 'mb-[15px]'}`}
                >
                  <MembershipTier
                    selectedTier={selectedTier}
                    onSelected={onTierSelected}
                    data={item}
                    paymentLink={paymentLink}
                    invalid={invalid}
                  />
                </div>
              </React.Fragment>
            );
          })}
        </div>
        <div className={invalid ? 'is-invalid' : ''} />
        <ErrorFeedback id={`multiple-tier-hint`}>{invalid && t('form.tierWarning')}</ErrorFeedback>
      </div>
    );
  }

  return null;
};

SelectTiers.defaultProps = {
  paymentLink: {},
  onTierSelected: () => {},
  invalid: false,
};

export default SelectTiers;
