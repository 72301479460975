import './style.scss';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Label } from '../ui/label';
import { UseFormReturn } from 'react-hook-form';
import { Card } from '../ui/card';
import { validator, formatRp, membershipPeriods } from '../../utils';
import { IMembeshipTier, IMembershipTierPeriod } from '@/composables/membership.type';
import { Input } from '../ui/input';

type FormValue = {
  payment: IMembershipTierPeriod
}

type SelectPricesType = {
  selectedTier: IMembeshipTier,
  formState: UseFormReturn<FormValue>,
  onValueChange: (value: IMembershipTierPeriod) => void,
  isVisible: boolean
}

export const SelectPrices: React.FC<SelectPricesType> = ({ selectedTier, formState, onValueChange, isVisible }) => {
  const { t } = useTranslation();
  const { register, formState: formUtil } = formState || {};
  const { errors } = formUtil;

  const [sortedTierPeriod, setSortedTierPeriod] = useState<IMembershipTierPeriod[]>([]);

  useEffect(() => {
    if (selectedTier?.membershipTierPeriod) {
      const arr = [...selectedTier?.membershipTierPeriod];

      const sorted = arr?.sort((a, b) => a.monthPeriod - b.monthPeriod);

      setSortedTierPeriod(sorted);
    }
  }, [selectedTier]);

  return (
    <div>
      {isVisible ? (
        <div className="mt-[15px] p-0">
          <h5 className="mb-[15px] w-full text-center">
            {t('form.membershipTiers')}
          </h5>
          <div className='flex flex-col gap-3'>
            {sortedTierPeriod?.map((item) => {
              if (item?.status !== 'ACTIVE') return;

              return (
                <Card className='py-4 px-4 shadow-none'>
                  <Label
                    htmlFor={item?.id}
                    key={item?.id}
                    // check
                    className="cursor-pointer radio-prices-tier"
                  >
                    <div className="flex items-center">
                      <Input
                        id={item?.id}
                        // name="payment"
                        type="radio"
                        value={item?.amount}
                        {...register("payment", { required: validator.required })}
                        invalid={Boolean(errors.payment)}
                        className="mt-0 w-3 h-3"
                        onClick={() => onValueChange(item)}
                      />

                      <span className="ml-[10px] text-[14px] font-light">
                        {formatRp(item?.amount)} {membershipPeriods[item?.monthPeriod as keyof typeof membershipPeriods]}
                        <br />
                      </span>
                      <span></span>
                    </div>
                  </Label>
                </Card>
                
              );
            })}
          </div>
          
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

// SelectPrices.defaultProps = {
//   isVisible: true,
// };

// SelectPrices.propTypes = {
//   payChannelConfig: PropTypes.object,
//   amount: PropTypes.number,
//   formState: PropTypes.object,
//   isVisible: PropTypes.bool,
//   onValueChange: PropTypes.func,
// };
