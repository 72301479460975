import * as React from "react";

// Define the interface for the component's props
export interface ErrorFeedbackProps {
  children?: React.ReactNode; // Optional children prop to allow for custom content
  [key: string]: any; // Allow any additional props
}

// Define the ErrorFeedback component
const ErrorFeedback: React.FC<ErrorFeedbackProps> = ({
  children,
  className,
  ...props
}) => {
  // Conditionally render the component only if there are children
  if (!children) {
    return null; // If there are no children, render nothing
  }

  return (
    <small
      className={`text-red-500 ${className} text-[10px]`} // Apply default styles and any custom classes passed in
      {...props} // Spread other props to the small element
    >
      {children} {/* Render children content */}
    </small>
  );
};

export { ErrorFeedback };
