import React, { FC } from "react";
import { Input } from "../ui/input";
import { Controller, useFormContext } from "react-hook-form";
import { validator } from "../../utils";
import { cn } from "@/lib/utils";

interface AmountInputProps {
  defaultValue?: number;
  onValueChange: (value: number) => void;
  placeholder?: string;
  isRequired?: boolean;
  min?: number;
  max?: number;
  className?: string;
}

export const AmountInput: FC<AmountInputProps> = ({
  defaultValue = 0,
  onValueChange,
  placeholder = "",
  min = 1000,
  max = 10000000,
  isRequired = false,
  className,
}) => {
  const { control, setError, clearErrors } = useFormContext();
  const isNumber = /^[0-9\b]+$/;
  const getValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const num = Number(e.currentTarget.value.replace(/\./g, "")) || 0;

    if (!isNumber.test(num.toString())) return;

    // error min if value < min || max if value > max || otherwise clear error
    if (num < min) {
      setError("amount", {
        type: "manual",
        message: `harus minimal bernilai ${min.toLocaleString("id")}`,
      });
    } else if (num > max) {
      setError("amount", {
        type: "manual",
        message: `harus maksimal bernilai ${max.toLocaleString("id")}`,
      });
    } else {
      clearErrors("amount");
    }

    return num;
  };

  return (
    <Controller
      name="amount"
      control={control}
      defaultValue={
        defaultValue === 0 ? "" : defaultValue?.toLocaleString("id")
      }
      rules={{ required: validator.isRequired(isRequired) }}
      render={({
        field: { onChange, onBlur, value, ref, name },
        fieldState: { error },
      }) => (
        <Input
          ref={ref}
          id={name}
          name={name}
          type="text"
          autoComplete="on"
          aria-describedby="amount-hint"
          onChange={(e) => {
            const num = getValue(e);
            const amountDisplay = num?.toLocaleString("id");
            onChange(num === 0 ? "" : amountDisplay);
            onValueChange(num || 0);
          }}
          value={value}
          onBlur={onBlur}
          invalid={Boolean(error)}
          placeholder={placeholder}
          className={cn(
            "w-full min-h-[36px] px-[17px] py-[7.5px] text-gray-700 placeholder-gray-400 rounded-l-none text-[1rem]",
            className
          )}
        />
      )}
    />
  );
};
