/* eslint-disable */
"use client";

import React, { useState, useEffect } from "react";
import "./style.scss";
import dynamic from 'next/dynamic';
import { useTranslation } from "react-i18next";
import { ErrorFeedback } from "../ui/error-feedback";
import { Button } from "../ui/button";
import {
  defaultChannelConfig,
  formatRp,
  membershipPeriods,
  temporaryPaymentDisabled,
} from "../../utils";
// import Icon from "../icon";
import { Icon } from "@iconify/react";

const CustomerInputCookies = dynamic(() => import("@/components/forms/customer-input-cookies"), {
  ssr: false,
});
const CustomFormBuilder = dynamic(() => import("../custom-form-builder"), {
  ssr: false,
});
// import CustomFormBuilder from "../custom-form-builder";

const CustomerInput = dynamic(() =>
  import("@/components/forms/customer-input").then((mod) => mod.CustomerInput), {
  ssr: false,
});
import {
  // CustomerInput,
  SelectPayment,
  FreebieInput,
} from ".";
import { checkPaymentConfig } from "../../utils/payment";
import SelectTiers from "./select-tiers";
import { SelectPrices } from "./choose-prices-variant";

const checkAvailPayment = (currentPayment = {}, amount, payChannelConfig) => {
  const configList = checkPaymentConfig(
    payChannelConfig?.config,
    defaultChannelConfig
  );
  const isCreditCardDisapproved = !(
    payChannelConfig?.isCreditCardApproved ?? false
  );
  const isAkulakuDisapproved = !(payChannelConfig?.isAkulakuApproved ?? false);

  const { type: currentType, code: currentCode } = currentPayment || {};
  const isCurrentPaymentDisabled = !(
    configList.find(
      (item) =>
        (item.code === currentCode || item.type === currentType) && item.status
    )?.status ?? false
  );

  return !(
    temporaryPaymentDisabled.includes(currentCode) ||
    isCurrentPaymentDisabled ||
    (currentType === "paylater" && isAkulakuDisapproved) ||
    (currentType === "card" && isCreditCardDisapproved) ||
    (currentType === "qris" && amount > 10_000_000) ||
    (currentType === "ewallet" && (amount < 100 || amount > 10_000_000)) ||
    (currentType === "ewallet" &&
      currentCode === "JENIUSPAY" &&
      amount < 1_000) ||
    (currentType === "va" && amount < 10_000) ||
    (currentType === "va" && currentCode === "BRI" && amount > 10_000_000) ||
    (currentType === "retail" && (amount < 10_000 || amount > 5_000_000)) ||
    (currentType === "retail" &&
      currentCode === "ALFAMART" &&
      amount > 2_500_000)
  );
};

export const OneClickMembership = ({
  useForm,
  paymentLink = {},
  parentState,
  onPaymentChange,
  setSelectedTierPeriod,
  onAmountProductChange,
  onTierSelected,
  selectedTier,
  amountChangedAfterFee,
  newStyleDonate = "",
  cartData,
  specificAmount,
  isDiscountUsed,
  isNotOneClick
}) => {
  const { t } = useTranslation();

  const form = useForm();
  const {
    finalAmount,
    isEmailReadOnly,
    selectedPayment,
    selectedTierPeriod,
    amountProduct,
    defaultCustomerCookies,
    userId,
  } = parentState;

  const {
    name,
    email,
    mobile,
    address,
    completeAddress,
    paymentData,
  } = defaultCustomerCookies;

  const [correctAmount, setCorrectAmount] = useState(
    amountChangedAfterFee ? amountChangedAfterFee : finalAmount
  );

  const [isCustInputVisible, setIsCustInputVisible] = useState(false);
  const [isPaymentVisible, setIsPaymentVisible] = useState(false);
  const [isPricesVisible, setIsPricesVisible] = useState(
    Boolean(selectedTierPeriod)
  );

  const [errorPayment, setErrorPayment] = useState(null);
  // const [initState, setInitState] = useState(true);

  const [isBtnChoosePaymentVisible, setIsBtnChoosePaymentVisible] =
    useState(false);
  const [isBtnChoosePricesVisible, setIsBtnChoosePricesVisible] =
    useState(false);

  useEffect(() => {
    if (amountChangedAfterFee) {
      setCorrectAmount(amountChangedAfterFee);
    } else {
      setCorrectAmount(finalAmount);
    }
  }, [amountChangedAfterFee, finalAmount]);

  useEffect(() => {
    if (isNotOneClick) {
      onPaymentChange(null);
      setIsCustInputVisible(true);
      setIsPaymentVisible(false);
      setIsPricesVisible(false);
      setIsBtnChoosePaymentVisible(true);
      setIsBtnChoosePricesVisible(true);
    } else {
      onPaymentChange(paymentData);
    }
  }, []);

  const handleSelectTier = (val) => {
    onTierSelected(val);
    setIsBtnChoosePricesVisible(true);
    setIsPricesVisible(false);
  };

  const togglePricesOptions = (bool) => {
    setIsPricesVisible(bool ?? !isPricesVisible);
    setErrorPayment(null);
  };

  const togglePayment = (bool) => {
    setIsPaymentVisible(bool ?? !isPaymentVisible);
    setErrorPayment(null);
  };

  useEffect(() => {
    if (isBtnChoosePaymentVisible && finalAmount > 0) {
      form.register("payment", { required: true });
    } else {
      form.unregister("payment");
    }
  }, [isBtnChoosePaymentVisible, finalAmount]);

  useEffect(() => {
    if (form.formState?.isSubmitting) {
      if (
        !selectedPayment &&
        !selectedTier?.isTrialAvailable &&
        finalAmount > 0
      ) {
        form.setError("payment", { type: "manual", message: "" });
      } else {
        form.clearErrors("payment");
      }
    }
  }, [form.formState?.isSubmitting, selectedPayment]);

  useEffect(() => {
    if (
      (paymentLink?.type !== "physical_product") &&
      selectedPayment
    ) {
      const isPaymentAvailable = checkAvailPayment(
        selectedPayment,
        finalAmount,
        paymentLink?.payChannelConfig
      );

      if (!isPaymentAvailable) {
        togglePayment();
        onPaymentChange(null);
      }
    }
  }, [finalAmount]);

  useEffect(() => {
    if (
      selectedTier?.isTrialAvailable
    ) {
      onPaymentChange(null);
      setIsPaymentVisible(false);
      setIsBtnChoosePaymentVisible(false);
    } else if (finalAmount >= 1000 && !isPaymentVisible && !selectedPayment) {
      setIsBtnChoosePaymentVisible(true);
    }
  }, [finalAmount, isPaymentVisible, selectedPayment, selectedTier]);

  useEffect(() => {
    if (selectedPayment) {
      setIsBtnChoosePaymentVisible(false);
    }
  }, [selectedPayment]);

  useEffect(() => {
    if (selectedTierPeriod) {
      setIsBtnChoosePricesVisible(false);
    }
  }, [selectedTierPeriod]);

  useEffect(() => {
    if (selectedTier && selectedTierPeriod) {
      togglePricesOptions(false);
    }
  }, [selectedTier]);

  const toggleCustInput = () => setIsCustInputVisible(!isCustInputVisible);

  return (
    <>
      {isCustInputVisible ? (
        <>
          <CustomerInput
            styleDonate={newStyleDonate}
            formState={form}
            defaultValues={defaultCustomerCookies}
            isEmailReadOnly={isEmailReadOnly}
            paymentLinkType={paymentLink?.type}
            paymeLink={paymentLink?.user?.paymeLink}
          />
        </>
      ) : (
        <CustomerInputCookies
          name={name}
          email={email}
          mobile={mobile}
          address={address}
          completeAddress={completeAddress}
          paymentLink={paymentLink}
          toggleCustInput={toggleCustInput}
        />
      )}
      <FreebieInput
        paymentType={paymentLink?.paymentType}
        amountMin
        formState={form}
        amount={amountProduct}
        specificAmount={specificAmount}
        onAmountChange={onAmountProductChange}
        paymeLink={paymentLink?.user?.paymeLink}
      />
      {paymentLink?.form && (
        <CustomFormBuilder form={paymentLink.form} formState={form} />
      )}

      <SelectTiers
        paymentLink={paymentLink}
        selectedTier={selectedTier}
        onTierSelected={handleSelectTier}
      />

      {selectedTier &&
        !selectedTier.isTrialAvailable && (
          <div className="w-full pt-[20px] px-[10px] mb-[15px]">
            {selectedTierPeriod && (
              <>
                <div className="flex flex-col items-start justify-center relative align-middle" vertical>
                  <Button
                    onClick={() => {
                      togglePricesOptions();
                      setSelectedTierPeriod(null);
                    }}
                    block
                    outline
                    variant="light"
                    className="flex w-full py-6 justify-center items-center text-black border-gray rounded-b-none bg-white rounded-t-sm"
                  >
                    <span className="text-center text-[12px]">
                      {formatRp(selectedTierPeriod?.amount)}{" "}
                      {membershipPeriods[selectedTierPeriod?.monthPeriod]}
                    </span>
                  </Button>
                  <Button
                    className="text-center py-4 border-gray mt-0 rounded-t-none border-t-0 w-full text-[12px] uppercase rounded-b-sm"
                    variant="light"
                    block
                    type="button"
                    onClick={() => {
                      togglePricesOptions();
                      setSelectedTierPeriod(null);
                    }}
                  >
                    {t("form.changeTierPrices")}
                  </Button>
                </div>
              </>
            )}

            {isBtnChoosePricesVisible && !selectedTierPeriod && (
              <>
                <Button
                  className="text-center pt-[10px] pb-[11px] border-gray w-full uppercase text-[12px]"
                  variant="light"
                  block
                  type="button"
                  onClick={() => {
                    togglePricesOptions();
                    setIsBtnChoosePricesVisible(false);
                  }}
                >
                  {t("form.chooseMembershipPrices")} &nbsp;{" "}
                  <Icon icon="tabler:chevron-down" />
                </Button>
                <div
                  className={Boolean(form.errors?.payment) ? "is-invalid" : ""}
                />
                <ErrorFeedback>{Boolean(form.errors?.payment) && t("checkout.paymentWarning")}</ErrorFeedback>
              </>
            )}

            <SelectPrices
              formState={form}
              amount={correctAmount}
              isVisible={isPricesVisible}
              selectedTier={selectedTier}
              onValueChange={(value) => {
                setSelectedTierPeriod(value);
                togglePricesOptions();
              }}
            />
          </div>
        )}

      {(amountProduct >= 1000) ||
      (cartData && amountProduct >= 10000) ? (
        <div className="w-full pt-[20px] px-[10px] mb-[15px]">
          {selectedPayment && (
            <>
              <div className="flex flex-col items-start justify-center relative align-middle" vertical>
                <Button
                  onClick={() => {
                    togglePayment();
                    onPaymentChange(null);
                  }}
                  block
                  outline
                  type="button"
                  variant="light"
                  className="flex w-full py-9 justify-between items-center pl-[30px] text-black border-gray rounded-b-none rounded-sm bg-white"
                >
                  {selectedPayment?.type === "qris" ? (
                    <>
                      <img
                        alt="Logo QRIS"
                        src={`/QrisBI.svg`}
                        width="130"
                        className="just-mobile"
                      />
                      <img
                        alt="Logo QRIS"
                        src={`/qris.svg`}
                        width="190"
                        className="just-tablet"
                      />
                    </>
                  ) : (
                    <img
                      alt={`Logo ${selectedPayment?.name}`}
                      src={selectedPayment?.img.replace(/.png/, ".svg")}
                      width={selectedPayment?.imgWidth}
                      className="ml-[12px]"
                    />
                  )}

                  <span className="mr-[12px] text-[12px] font-[500] uppercase">{selectedPayment?.name}</span>
                </Button>
                <Button
                  className="text-center py-4 border-gray mt-0 rounded-t-none border-t-0 w-full font-[500] uppercase text-[10px] rounded-sm"
                  variant="light"
                  block
                  type="button"
                  onClick={() => {
                    togglePayment();
                    onPaymentChange(null);
                  }}
                >
                  {t("form.changePayment")}
                </Button>
              </div>
              <div className={Boolean(errorPayment) ? "is-invalid" : ""} />
              <ErrorFeedback>{Boolean(errorPayment) && t("form.quantityWarning")}</ErrorFeedback>
            </>
          )}

          {isBtnChoosePaymentVisible && (
            <>
              <Button
                className="text-center border-gray btn-choose-payment w-full py-4 uppercase font-[500] text-[11px] rounded-sm"
                variant="light"
                block
                type="button"
                onClick={() => {
                  togglePayment();
                  setIsBtnChoosePaymentVisible(false);
                }}
              >
                {t("form.choosePayment")} &nbsp; <Icon icon="tabler:chevron-down" />
              </Button>
              <div
                className={Boolean(form.errors?.payment) ? "is-invalid" : ""}
              />
              <ErrorFeedback>{Boolean(form.errors?.payment) && t("checkout.paymentWarning")}</ErrorFeedback>
            </>
          )}

          <SelectPayment
            payChannelConfig={paymentLink?.payChannelConfig}
            formState={form}
            amount={correctAmount}
            isVisible={isPaymentVisible}
            onValueChange={(value) => {
              onPaymentChange(value);
              togglePayment();
            }}
          />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

