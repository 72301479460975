import React, { useState, useEffect } from 'react';
import Select from 'react-select';

import { useFormContext, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MayarAlertBase } from '../ui/mayar-alert-base';
import { Writing } from '@/composables/writing.type';
import { ErrorFeedback } from '../ui/error-feedback';
import { formatRp, validator } from '../../utils';
import { CustomStyles, CustomStylesInvalid } from '../custom-styles/react-select-styles';

type ChapterSelectType = {
  chapter: Partial<Writing>
}

type SelectChaptersType = {
  chapters: Partial<Writing>[]
  selectedChapter: Partial<Writing>
  onChapterSelected: (chapter: Partial<Writing>) => void
  paymentLink: any
}

const Chapter: React.FC<ChapterSelectType> = ({ chapter }) => {
  return (
    <div className="card-variant-item border-0 m-0 p-0 sidebar-variant">
      <div className="ticket m-0">
        <div className="ticket__left"></div>
        <div className="ticket__content">
          <div className="ticket--title mb-[10px]">
            <h4 className="ticket--name m-0">{chapter.title}</h4>
          </div>

          <h5 className="text-primary text-sm">{formatRp(chapter.amount)}</h5>
        </div>
      </div>
    </div>
  );
};

const SelectChapters: React.FC<SelectChaptersType> = ({
  chapters, onChapterSelected, selectedChapter, paymentLink
}) => {
  const { t } = useTranslation();
  const { formState, control } = useFormContext();
  const { errors } = formState;
  const chaptersOption = chapters.map((item) => ({ value: item.id, label: item.title }))
  const [currentChapter, setCurrentChapter] = useState(chapters.find(({ id }) => id === selectedChapter?.id));

  useEffect(() => {
    if (selectedChapter?.id) {
      const updateVariant = chapters.find(({ id }) => id === selectedChapter?.id);
      updateVariant && onChapterSelected(updateVariant);
      setCurrentChapter(updateVariant);
    }
  }, [selectedChapter]);

  if (!chapters.length && paymentLink?.writingType === "CHAPTER" && (paymentLink?.writingPaymentMethod && paymentLink?.writingPaymentMethod !== "ALL")) {
    return (
      <MayarAlertBase variant="light" className="text-black text-center">
        {t('alert.unactiveTier')}
      </MayarAlertBase>
    )
  }

  if (chapters.length && selectedChapter) {
    return (
      <div className='pt-[20px] px-[10px] w-full'>
        <h5 className="mb-[15px] w-full text-center font-[500]">
          {t('word.choose')} Chapter
        </h5>

        <div className='mb-[20px] text-sm'>
          <Controller
            name="chapter"
            control={control}
            rules={{ required: validator.required }}
            defaultValue={
              chaptersOption?.find((item) => item.value === selectedChapter?.id) || null
            }
            
            // render={(props: { name: string | undefined; value: any; onChange: (arg0: any) => void; }) => (
            render={({ field, fieldState, formState }) => (
              <Select
                // id={field.name}
                id="react-select-chapter"
                name={field.name}
                placeholder={"Chapter"}
                options={chaptersOption}
                value={field.value}
                styles={Boolean(formState.errors.chapter) ? CustomStylesInvalid : CustomStyles}
                className={`form-control-lg p-0 checkout-input text-[1rem] w-full ${
                  Boolean(formState.errors.chapter) ? 'is-invalid' : ''
                }`}
                onChange={(e) => {
                  const updatedChapter = chapters.find(({ id }) => id === e.value);
                  field.onChange(e);
                  if (updatedChapter) {
                    onChapterSelected(updatedChapter);
                  }
                  setCurrentChapter(updatedChapter);
                }}
              />
            )}
          />
          <ErrorFeedback id="variant-hint">{errors?.chapter && t('form.variantWarning')}</ErrorFeedback>
        </div>

        {currentChapter ? (
          <Chapter chapter={currentChapter} />
        ) : (
          ''
        )}
      </div>
    );
  }

  return null;
};

export default SelectChapters;
