import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { validator } from '../../utils';
import { UseFormReturn, Controller } from "react-hook-form"
import { Input } from '../ui/input';
import { ErrorFeedback } from '../ui/error-feedback';
import { Textarea } from '../ui/textarea';
import { Label } from "@/components/ui/label"
import { Switch } from "@/components/ui/switch"

type FormType = {
  emailRecipientGift: string;
  nameRecipientGift: string;
  mobileRecipientGift: string;
  messageRecipientGift: string;
  isGift: boolean;
}

type GivenCustomerInputType = {
  isSupportGift: boolean;
  formState: UseFormReturn<FormType>
}

const GivenCustomerInput: React.FC<GivenCustomerInputType> = ({ formState, isSupportGift }) => {
  const { t } = useTranslation();
  const { register, control, formState: formUtil } = formState;
  const { errors } = formUtil
  const [isGift, setIsGift] = useState<boolean>(false)

  return (
    <>
      {isSupportGift && (
        <div className="w-full pt-[20px] px-[10px] text-sm">
          <div className="flex items-center gap-3">
            <Controller
              name="isGift"
              control={control}
              defaultValue={false}
              render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
                <Switch
                  id="isGift" className='bg-gray-300'
                  // ref={ref}
                  aria-describedby="gift-hint"
                  {...register('isGift')}
                  onCheckedChange={(e) => {
                    onChange(e);
                    setIsGift(e);
                  }}
                  // value={value}
                />
              )}
            />
            <Label htmlFor="isGift" className='mb-0 text-[13px] font-[400]'>Berikan Sebagai Hadiah</Label>
          </div>
        </div>
      )}
      {isSupportGift && isGift && (
        <>
          <div className="w-full pt-[20px] px-[10px]">
            <Input
              id="emailRecipientGift"
              // name="emailRecipientGift"
              type="email"
              autoComplete="email"
              aria-describedby="email-hint"
              placeholder={t('word.emailRecipientGift')}
              invalid={Boolean(errors.emailRecipientGift)}
              {...register('emailRecipientGift', {
                required: validator.required,
                pattern: validator.email,
              })}
              className='checkout-input bg-[#fbfcfc] min-h-[36px] px-[17px] pt-[7.5px] pb-[9px] text-[1rem] border-[1px] border-[#e6ecf0]'
            />
            <ErrorFeedback id="email-hint">{errors.emailRecipientGift?.message}</ErrorFeedback>
          </div>
          <div className="w-full pt-[20px] px-[10px]">
            <Input
              id="nameRecipientGift"
              // name="nameRecipientGift"
              type="text"
              autoComplete="name"
              aria-describedby="name-hint"
              placeholder={t('word.nameRecipientGift')}
              invalid={Boolean(errors.nameRecipientGift)}
              {...register('nameRecipientGift', {
                required: validator.required,
                minLength: validator.minLength(3),
              })}
              className='checkout-input bg-[#fbfcfc] min-h-[36px] px-[17px] pt-[7.5px] pb-[9px] text-[1rem] border-[1px] border-[#e6ecf0]'
            />
            <ErrorFeedback id="name-hint">{errors.nameRecipientGift?.message}</ErrorFeedback>
          </div>
          <div className="w-full pt-[20px] px-[10px]">
            <Input
              id="mobileRecipientGift"
              // name="mobileRecipientGift"
              type="tel"
              autoComplete="tel"
              aria-describedby="tel-hint"
              placeholder={t('placeholder.phone')}
              invalid={Boolean(errors.mobileRecipientGift)}
              {...register('mobileRecipientGift', {
                required: validator.required,
                pattern: validator.phone,
                minLength: validator.minLength(10),
                maxLength: validator.maxLength(15),
              })}
              className='checkout-input bg-[#fbfcfc] min-h-[36px] px-[17px] pt-[7.5px] pb-[9px] text-[1rem] border-[1px] border-[#e6ecf0]'
            />
            <ErrorFeedback id="tel-hint">{errors.mobileRecipientGift?.message}</ErrorFeedback>
          </div>
          <div className="w-full pt-[20px] px-[10px]">
            <Textarea
              id="messageRecipientGift"
              // name="messageRecipientGift"
              // type="textarea"
              autoComplete="tel"
              aria-describedby="tel-hint"
              placeholder={t('word.message')}
              {...register('messageRecipientGift')}
              className='checkout-input bg-[#fbfcfc] min-h-[10rem] px-[17px] pt-[7.5px] pb-[9px] text-[1rem] border-[1px] border-[#e6ecf0]'
            />
          </div>
        </>
      )}
    </>
  )
}

export default GivenCustomerInput
