"use client";

import './style.scss';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { GET_MEMBERSHIP_CUSTOMER_TOTAL } from '../../gqls';
import ReadMore from '../read-more';

const MembershipTier = ({ onSelected, data, invalid, selectedTier }) => {
  const { t } = useTranslation();

  const [isSoldOut, setIsSoldOut] = useState(false);
  const [isReadMore, setIsReadMore] = useState(false);

  const { name, description } = data;

  // Remove the html tag so the text is trimming the correct sentence
  const regex = /(<([^>]+)>)/gi;
  const tierDescription = description?.replace(regex, '');

  const { data: dataTotal } = useQuery(GET_MEMBERSHIP_CUSTOMER_TOTAL, {
    context: {
      public: true,
    },
    variables: {
      membershipTierId: data?.id,
    },
  });

  useEffect(() => {
    if (dataTotal?.getTotalMembershipCustomer?.total) {
      const totalCustomer = dataTotal?.getTotalMembershipCustomer?.total;

      if (data?.limit > 0 && totalCustomer >= data?.limit) {
        setIsSoldOut(true);
      }
    }
  }, [dataTotal, data]);

  const handleSelectingTier = (tier) => {
    onSelected(tier);
  };

  const toggleReadMore = (e) => {
    e.stopPropagation();
    setIsReadMore(!isReadMore);
  };

  return (
    <div
      className={`ticket ${invalid ? 'is-invalid' : ''} ${
        selectedTier?.id === data?.id ? 'border-[1px] !border-[#017bfe]' : ''
      } cursor-pointer`}
      onClick={() => !isSoldOut && handleSelectingTier(data)}
      onKeyDown={() => !isSoldOut && handleSelectingTier(data)}
      role="button"
      tabIndex={0}
    >
      <div className="ticket__left"></div>
      <div className="ticket__content">
        <div className="ticket--title">
          <h4 className="ticket--name">{name}</h4>
          {isSoldOut && <h4 className="text-danger">{t('word.soldOut')}</h4>}
        </div>

        <hr className='mt-2 mb-3' />

        <ReadMore
          isHTML
          description={description}
          limit={100}
          isCanReadLess
          toggleTextAlign="left"
        />
      </div>
    </div>
  );
};

MembershipTier.defaultProps = {
  formState: {},
  data: {},
  paymentLink: {},
  onTierSelected: () => {},
  eventHistory: {},
  isDisabled: false,
  invalid: false,
};

export default MembershipTier;
