"use client";

import './style.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { MayarAlertBase } from '../ui/mayar-alert-base';
import { useTranslation } from 'react-i18next';

type MayarAlertType = {
  isError?: boolean;
  isSuccess?: boolean;
  message?: string;
  customClass?: string;
  redirectLink?: string;
  wordToRedirectLink?: string;
  onCloseAlert?: () => void; // Add this line
  isCloseable?: boolean;
}

const MayarAlert: React.FC<MayarAlertType> = ({ isError, isSuccess, message, customClass = '', redirectLink = null, wordToRedirectLink = null, ...props }) => {
  const { t } = useTranslation();
  // TODO: write better message based on the graphql response

  return (
    <>
      {isError && (
        <MayarAlertBase variant="danger" {...props} className={`font-semibold mt-8 ${customClass}`}>
          {message || t('global.err')} {redirectLink && wordToRedirectLink && (<a href={redirectLink}>{wordToRedirectLink}</a>)}
        </MayarAlertBase>
      )}
      {isSuccess && (
        <MayarAlertBase variant="success" {...props}>
          {message || t('global.success')}
        </MayarAlertBase>
      )}
    </>
  );
};

export default MayarAlert;
