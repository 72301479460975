import React, { useEffect } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useLazyQuery } from '@apollo/client';
import { UseFormReturn } from 'react-hook-form';
import { GQL_GET_TOTAL_SOLD_ORDER } from '../../gqls';
import TicketShape from '../ticket-shape';
import { MayarAlertBase } from '../ui/mayar-alert-base';

// type
import { IPaymentLink } from '@/composables/paymentlink.type';
import { ITicket } from '@/composables/ticket.type';
import { ErrorFeedback } from '../ui/error-feedback';
import { RemainingStock } from '@/common/types/ticket.type';

type SelectTicketsType = {
  isDiscountUsed: boolean;
  invalid: boolean;
  isDisabled: boolean;
  paymentLink: IPaymentLink;
  onTicketSelected: React.Dispatch<React.SetStateAction<ITicket[]>>;
  formState: UseFormReturn<Record<string, number>>
}

const SelectTickets: React.FC<SelectTicketsType> = ({
  paymentLink,
  onTicketSelected,
  formState,
  isDisabled,
  invalid,
  isDiscountUsed
}) => {
  const { t } = useTranslation();
  const [fetchEventHistory, { data: eventHistoryData }] = useLazyQuery(GQL_GET_TOTAL_SOLD_ORDER, {
    context: { public: true },
  });

  const eventHistory: RemainingStock = _.get(eventHistoryData, 'getTotalSoldOrder');
  const { formState: formUtil } = formState;
  const { errors } = formUtil;
  console.log({tiketerror: errors})
  const ticketEvents =
    paymentLink?.event?.ticketEvent
      ?.filter(({ isVisible }) => isVisible)
      ?.sort((a, b) => a.position - b.position) || [];

  useEffect(() => {
    if (paymentLink?.type === 'event') {
      fetchEventHistory({
        variables: { link: paymentLink?.link },
      });
    }
  }, [paymentLink]);

  if (paymentLink?.type === 'event') {
    return (
      <div className="w-full pt-[20px] px-[10px] text-[0.9rem] font-[500]">
        {isDisabled ? (
          <h5 className="mb-[15px] w-full text-center">
            Ticket
          </h5>
        ) : (
          <h5 className="mb-[15px] w-full text-center">
            {t('form.chooseTicket')}
          </h5>
        )}

        {ticketEvents?.length < 1 && (
          <MayarAlertBase variant="light" className="text-black text-center">
            {t('alert.unactiveTicket')}
          </MayarAlertBase>
        )}

        {/* <MayarAlertBase variant="light" className="text-black text-center">
          {t('alert.unactiveTicket')}
        </MayarAlertBase> */}

        <div className="flex flex-col my-0 w-full mx-auto">
          {ticketEvents?.map((item, index) => {
            const lastIndex = ticketEvents?.length - 1;

            return (
              <React.Fragment key={item.id}>
                {/* <ListGroupItem
                  className={`p-0 border-0 w-100 ${index === lastIndex ? '' : 'mb-15'}`}
                > */}
                  <div className={`p-0 border-0 w-full ${index === lastIndex ? '' : 'mb-4'}`}>
                    <TicketShape
                      onTicketSelected={onTicketSelected}
                      data={item}
                      eventHistory={eventHistory}
                      isDisabled={isDisabled}
                      paymentLink={paymentLink}
                      formState={formState}
                      invalid={invalid}
                      isDiscountUsed={isDiscountUsed}
                    />
                  </div>
                  
                {/* </ListGroupItem> */}
              </React.Fragment>
            );
          })}
        </div>
        <div className={invalid ? 'is-invalid' : ''} />
        <ErrorFeedback id={`multiple-ticket-hint`}>{invalid && t('form.ticketWarning')}</ErrorFeedback>
      </div>
    );
  }

  return null;
};

// SelectTickets.defaultProps = {
//   paymentLink: {},
//   onTicketSelected: () => {},
//   formState: {},
//   isDisabled: false,
//   invalid: false,
//   isDiscountUsed: false,
// };

export default SelectTickets;
