import './style.scss';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from '../ui/input';
import { Card } from '@/components/ui/card';
import { formatRp, onlyUniqueObjectOfArray } from '../../utils';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion"
// import Icon as MayarIcon from '../icon';
import { UseFormReturn } from 'react-hook-form';
import { Icon } from "@iconify/react";
import { ITicket } from "@/composables/ticket.type"
import { IPaymentLink } from '@/composables/paymentlink.type';
import { RemainingStock } from '@/common/types/ticket.type';
import { cn } from '@/lib/utils';
import { Button } from '../ui/button';

type TicketShapeType = {
  onTicketSelected: React.Dispatch<React.SetStateAction<ITicket[]>>;
  data: ITicket & { isTemporarySoldOut?: string };
  eventHistory: RemainingStock;
  isDisabled: boolean;
  paymentLink: IPaymentLink;
  formState: UseFormReturn<Record<string, number>>
  invalid: boolean;
  isDiscountUsed: boolean;
}

const TicketShape: React.FC<TicketShapeType> = ({
  onTicketSelected,
  data,
  eventHistory,
  isDisabled,
  paymentLink,
  formState,
  invalid,
  isDiscountUsed,
}) => {
  const { t } = useTranslation();

  const [quantity, setQuantity] = useState(0);

  const { register } = formState;
  const {
    id,
    name,
    amount,
    ticketEndDate,
    ticketStartDate,
    description,
    isVisible,
    quantity: stockTicket,
  } = data;

  const currentDate = new Date();
  const totalSold = eventHistory?.detailEvent?.find((obj) => obj.id === id)?.qty || 0;
  const actualStock = stockTicket - totalSold;

  useEffect(() => {
    onTicketSelected((prevState) =>
      onlyUniqueObjectOfArray(
        [
          {
            id,
            qty: Number(quantity),
            name,
          },
          ...prevState,
        ],
        'id',
      ),
    );
  }, [quantity]);

  const checkMaxQuantity = () => {
    let totalLimit = paymentLink?.event?.maxTicket;

    if (actualStock < paymentLink?.event?.maxTicket) {
      totalLimit = actualStock;
    }

    return quantity === totalLimit ? 'disable' : 'clickable';
  };

  const displayCounter = () => {
    if (data?.isTemporarySoldOut) {
      return <h4 className="text-red-500">Waiting List</h4>;
    }

    // Check ticket quantity
    const addQuantity = () => setQuantity(quantity + 1);
    const reduceQuantity = () => setQuantity(quantity - 1);

    if (actualStock > 0) {
      if (isDisabled || !isVisible || isDiscountUsed) {
        return '';
      } else if (ticketEndDate && new Date(ticketEndDate) > currentDate) {
        // Check ticket expired time
        return (
          <div className="ticket__counter">
            <button
              type="button"
              onClick={reduceQuantity}
              className={cn(
                'p-0',
                quantity === 0 ? 'disable' : 'clickable'
              )}
            >
              <Icon icon="ph:minus" />
            </button>
            <Input
              id={`multiple-ticket-${id}`}
              // name={`multiple-ticket-${id}`}
              type="number"
              readOnly
              className="ticket--number text-[1rem] text-black"
              {...register(`multiple-ticket-${id}`)}
              // innerRef={register()}
              value={quantity}
              disabled
            />
            <button type="button" onClick={addQuantity} className={checkMaxQuantity()}>
              <Icon icon="ph:plus" />
            </button>
          </div>
          // <div className="flex w-[6rem]">
          //   <Button
          //     type="button"
          //     variant="secondary"
          //     onClick={reduceQuantity}
          //     className={cn(
          //       'p-2',
          //       quantity === 0 ? 'disable' : 'clickable'
          //     )}
          //   >
          //     <Icon icon="ph:minus" />
          //   </Button>
          //   <Input
          //     id={`multiple-ticket-${id}`}
          //     // name={`multiple-ticket-${id}`}
          //     type="number"
          //     readOnly
          //     className="ticket--number text-[1rem] text-black text-center w-[3rem]"
          //     {...register(`multiple-ticket-${id}`)}
          //     // innerRef={register()}
          //     value={quantity}
          //     disabled
          //   />
          //   <Button
          //     variant="secondary"
          //     type="button"
          //     onClick={addQuantity}
          //     className={cn(
          //       checkMaxQuantity(),
          //       "p-0"
          //     )}
          //   >
          //     <Icon icon="ph:plus" />
          //   </Button>
          // </div>
        );
      } else if (!ticketEndDate) {
        return (
          <div className="ticket__counter">
            <button
              type="button"
              onClick={reduceQuantity}
              className={cn(
                'w-[1rem] h-[1rem]',
                quantity === 0 ? 'disable' : 'clickable'
              )}
            >
              <Icon icon="ph:minus" />
            </button>
            <Input
              id={`multiple-ticket-${id}`}
              // name={`multiple-ticket-${id}`}
              type="number"
              readOnly
              className="ticket--number"
              {...register(`multiple-ticket-${id}`)}
              value={quantity}
              disabled={isDisabled || !isVisible || isDiscountUsed}
            />
            <button type="button" onClick={addQuantity} className={checkMaxQuantity()}>
              <Icon icon="ph:plus" />
            </button>
          </div>
        );
      }
    }

    // Handle sold out
    // in case sold out ticket more than ticket stock
    if (actualStock <= 0) {
      return <h4 className="text-red-500">{t('word.soldOut')}</h4>;
    }
  };

  const Expired = () => {
    if (ticketEndDate && currentDate > new Date(ticketEndDate)) {
      return <span className="ticket--badge ticket--badge--success">{t('event.end')}</span>;
    }

    return '';
  };

  const StartDate = () => {
    if (currentDate < new Date(ticketStartDate)) {
      return <span className="ticket--badge ticket--badge--danger">{t('form.notStarted')}</span>;
    }

    return '';
  };

  return (
    <div className={`ticket ${invalid ? 'is-invalid' : ''}`}>
      <div className="ticket__left">
        <span className="ticket__circle circle--bottom">
          <span className="line"></span>
        </span>
        <span className="ticket__circle circle--top">
          <span className="line"></span>
        </span>
      </div>
      <div className="ticket__content">
        <div className="ticket--title">
          <h4 className="ticket--name font-light text-[1rem]">{name}</h4>
          <h4>
            <StartDate />
            <Expired />
          </h4>
        </div>
        {/* <div className="ticket--line mt-[1rem]" /> */}
        <hr className='my-2' />
        {description && (
          <>
            {/* <p className="cursor-pointer mb-0" id={`toggler${id}`}>
              <i>{t('form.ticketDesc')}</i> <Icon icon="ion:chevron-down" />
            </p> */}
            <Accordion type="single" collapsible className="w-full outline-none focus:outline-none">
              <AccordionItem value="item-1" className='border-b-0 focus:outline-none'>
                <AccordionTrigger className='py-1'>
                  <p className="cursor-pointer mb-0 text-[0.8rem] font-light italic" id={`toggler${id}`}>
                    {t('form.ticketDesc')}
                  </p>
                </AccordionTrigger>
                <AccordionContent>
                  <Card className=" shadow-none">
                    <div className="py-2 px-2 bg-white border-none font-light text-[12px]">{description}</div>
                  </Card>
                </AccordionContent>
              </AccordionItem>
            </Accordion>
            {/* <UncontrolledCollapse toggler={`#toggler${id}`}>
              <Card className="mb-5 shadow-none">
                <div className="py-5 px-10 bg-white border-none">{description}</div>
              </Card>
            </UncontrolledCollapse> */}
          </>
        )}

        {/* Open sale */}
        {/* {ticketEndDate && currentDate > new Date(ticketStartDate) && (
          <p className="text-primary ticket--blue mb-10 mt-10">
            <small>
              {t('form.endDate')}{' '}
              {moment(ticketEndDate).tz(getUserTimezone()).format('D MMM YYYY HH:mm z')}
            </small>
          </p>
        )} */}

        {/* Start sale */}
        {/* {currentDate < new Date(ticketStartDate) && (
          <p className="text-primary ticket--blue mb-10 mt-10">
            <small>
              {t('form.startDate')}{' '}
              {moment(ticketStartDate).tz(getUserTimezone()).format('D MMM YYYY HH:mm z')}
            </small>
          </p>
        )} */}

        <div className="ticket__content__bottom">
          <h4 className="ticket--price">
            <b>
              {amount > 0 ? (
                formatRp(amount)
              ) : (
                <span className="text-green-500">{t('word.free')}</span>
              )}
            </b>
          </h4>
          {currentDate > new Date(ticketStartDate) && displayCounter()}
        </div>
      </div>
    </div>
  );
};

export default TicketShape;
